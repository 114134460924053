/**
* Tooltip
*/

:root {
  --mat-table-row-item-outline-color: rgb(var(--gray-200) / 1);
}

.mdc-tooltip__surface {
  font-size: 0.75rem !important;
  background-color: var(--dark-bg) !important;
  padding: 6px 8px !important;
}

.tooltip-break {
  white-space: pre-line;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  white-space: pre-line;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-shape: var(--border-radius-lg) !important;
}

/**
* Quill
*/
.ql-toolbar {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.ql-container {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

/**
* Mat Tab
*/
.mat-mdc-tab-body-content {
  padding-top: 20px;
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  @media (min-width: 640px) {
    padding-top: 24px;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: rgb(var(--primary) / 1) !important;
}

.mat-mdc-tab {
  .mdc-tab__content,
  .mdc-tab__text-label {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    letter-spacing: 0px !important;
  }
}

.mdc-tab--active {
  opacity: 1 !important;
  .mdc-tab__content,
  .mdc-tab__text-label {
    color: rgb(var(--primary) / 1) !important;
    font-size: 0.875rem !important;
    font-weight: 500;
  }
  .mdc-tab-indicator__content--underline {
    border-color: rgb(var(--primary) / 1) !important;
  }
}

.mat-mdc-tab:hover .mdc-tab__ripple::before {
  opacity: 0 !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/**
* Border Radius
*/
.mat-mdc-card {
  border-radius: var(--border-radius) !important;
}

/**
* Mat Table
*/

.mat-sort-header-content {
  text-align: left !important;
}

.hoverable-table {
  .mat-mdc-row:hover {
    @apply bg-gray-100;
    cursor: pointer;
  }
}

.mdc-data-table__content,
.mdc-data-table__cell {
  -webkit-font-smoothing: auto !important;
  letter-spacing: unset !important;
}

/**
* Form Fields
*/
.mat-form-field-label {
  font-size: 0.9rem !important;
}

/* Expansion Panel */
.mat-expansion-panel:not(.no-shadow) {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}
.mat-expansion-indicator::after {
  @apply border-gray-300;
}
.mat-expansion-panel-header[aria-disabled='true'] {
  color: unset;
}

/* Paginator */
mat-paginator {
  --mdc-outlined-text-field-container-shape: 4px;

  svg.mat-mdc-paginator-icon {
    height: 20px;
    width: 20px;
  }
}

.mat-mdc-select-panel {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  background: white;
  --mat-select-panel-background-color: var(--white);
}

.mat-mdc-option {
  font-size: 0.875rem !important;
  min-height: 40px !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text {
  @apply text-primary #{!important};
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  @apply border-primary #{!important};
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  @apply border-gray-300 #{!important};
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: var(--border-radius-md) !important;
  border-bottom-left-radius: var(--border-radius-md) !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: var(--border-radius-md) !important;
  border-bottom-right-radius: var(--border-radius-md) !important;
}
/* Mat Dialog */

.mdc-dialog__container {
  .mdc-dialog__surface {
    padding: var(--mat-dialog-padding, 1.5rem);
  }
}

.fullscreen.cdk-overlay-pane {
  height: 100%;
  max-height: 100% !important;
  width: 100%;
  max-width: 100% !important;
  transition: all 300ms ease-in-out;

  .mat-mdc-dialog-container {
    max-height: 100% !important;
    max-width: 100% !important;

    .mdc-dialog__surface {
      --mdc-dialog-container-shape: 0;
    }
  }
}

.dialog-p-0 {
  .mdc-dialog__container .mdc-dialog__surface {
    padding: 0 !important;
  }
}

.mat-mdc-dialog-container {
  max-height: 95vh !important;
}

.cdk-overlay-container {
  z-index: 10000;
}

.dark-backdrop {
  background: rgb(0 0 0 / 80%);
}

.mat-dialog-container {
  border-radius: var(--border-radius-lg) !important;
}

/* Mat Spinner */
.mat-mdc-progress-spinner circle :not(.spinner-default),
.mat-spinner circle :not(.spinner-default),
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: rgb(var(--primary) / 1) !important;
}

/* Mat progress bar */
.mdc-linear-progress__bar-inner {
  border-color: rgb(var(--primary) / 1) !important;
}
// .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
//   background-color: unset;
// }

/* Mat Menu */
.panel-0 {
  max-width: unset !important;
  overflow-x: hidden !important;
  .mat-mdc-menu-content {
    padding: 0px !important;
  }
}

.mat-menu-full {
  .mat-mdc-menu-content {
    @apply h-full;
  }
}
.mat-menu-full > .mat-mdc-menu-content > div {
  @apply h-full;
}

.isDarkTheme {
  .mat-mdc-menu-content {
    background-color: var(--white) !important;
  }
}

.mat-mdc-menu-content {
  min-width: 240px;
}

.mat-mdc-menu-content .mat-mdc-menu-item {
  --mat-menu-item-label-text-size: 0.875rem !important;
  --mat-menu-item-label-text-line-height: 1.25rem !important;
  --mat-menu-item-label-text-tracking: normal !important;
  --mat-menu-item-label-text-color: rgb(
    var(--mspot-neutral-600) / 1
  ) !important;

  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  letter-spacing: normal !important;
  @apply text-gray-600 #{!important};
  -webkit-font-smoothing: auto !important;
}
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  -webkit-font-smoothing: auto !important;
}

.mat-sort-header-container {
  letter-spacing: inherit !important;
}
.mdc-data-table__header-cell {
  -webkit-font-smoothing: auto !important;
}

.mat-mdc-menu-content {
  -webkit-font-smoothing: auto !important;
  padding: 0.5rem !important;

  ng-icon {
    @apply text-xl;
  }
}

.mat-mdc-menu-panel {
  @apply rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 #{!important};
}

.isDarkTheme {
  .mat-mdc-menu-panel {
    @apply rounded-xl shadow-lg ring-1 ring-white ring-opacity-5 #{!important};
  }
}

.mat-mdc-menu-panel {
  min-height: 20px !important;
}

/*
  Horizontal items in mat-menu
*/

.horizontal-menu {
  .mat-mdc-menu-content {
    display: flex;
  }
}

.mat-mdc-menu-item {
  display: flex !important;
  align-items: center;
  @apply rounded-lg text-sm text-gray-600 #{!important};
}

.mat-mdc-menu-item:not([disabled]):hover {
  @apply bg-gray-100 text-gray-800 #{!important};
}

.mat-mdc-menu-submenu-icon {
  @apply text-gray-500 #{!important};
}

.mat-mdc-menu-item:not(.large) {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  padding: 0.5rem 0.75rem !important;
  @apply gap-2;
}
.mat-mdc-menu-item.large {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  padding: 0 0.75rem !important;
}

.mat-bottom-sheet-container-p-0 {
  overflow: hidden;
  border-radius: 0.5rem 0.5rem 0 0;

  .mat-bottom-sheet-container {
    background-color: rgb(var(--mspot-white) / 1);
    padding: 0 !important;
    max-height: unset !important;
  }
}

/* Mat Bottom Sheed */
.mat-bottom-sheet-container {
  padding: 0.5rem 2rem 3rem !important;
  ng-icon {
    @apply text-gray-600;
  }
}

/* Timepicker */
.mat-toolbar.mat-primary {
  background-color: rgb(var(--primary) / 1);
  color: white;
}

.mat-mdc-mini-fab.mat-primary {
  background-color: rgb(var(--primary) / 1);
  color: white;
}

/* Calendar */
.mat-calendar-body-selected {
  background-color: rgb(var(--primary) / 1) !important;
}
