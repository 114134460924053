/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
/* purgecss end ignore */

@import 'tailwindcss/utilities';
@import '../styles/mixins/actions';

@layer base {
  :root {
    // https://tailwindcss.com/docs/customizing-colors#using-css-variables
    // from figma
    --mspot-primary-50: 235 239 255;
    --mspot-primary-100: 194 206 255;
    --mspot-primary-200: 155 175 253;
    --mspot-primary-300: 115 143 252;
    --mspot-primary-400: 75 110 251;
    --mspot-primary-500: 44 83 242;
    --mspot-primary-600: 24 64 220;
    --mspot-primary-700: 20 53 184;
    --mspot-primary-800: 16 42 147;
    --mspot-primary-900: 12 32 110;
    --mspot-primary-950: 8 21 73;

    --mspot-secondary-50: 251 238 243;
    --mspot-secondary-100: 246 202 220;
    --mspot-secondary-200: 241 167 197;
    --mspot-secondary-300: 241 126 172;
    --mspot-secondary-400: 244 82 147;
    --mspot-secondary-500: 242 44 123;
    --mspot-secondary-600: 220 24 103;
    --mspot-secondary-700: 184 20 86;
    --mspot-secondary-800: 147 16 69;
    --mspot-secondary-900: 110 12 51;
    --mspot-secondary-950: 73 8 34;

    --mspot-neutral-50: 249 249 251;
    --mspot-neutral-100: 243 244 246;
    --mspot-neutral-200: 229 230 235;
    --mspot-neutral-300: 209 211 219;
    --mspot-neutral-400: 156 160 175;
    --mspot-neutral-500: 107 111 128;
    --mspot-neutral-600: 75 80 99;
    --mspot-neutral-700: 55 60 81;
    --mspot-neutral-800: 31 36 55;
    --mspot-neutral-900: 17 21 39;
    --mspot-neutral-950: 3 6 18;

    --mspot-information-50: 245 248 255;
    --mspot-information-100: 235 240 255;
    --mspot-information-200: 195 211 254;
    --mspot-information-600: 53 101 233;
    --mspot-information-800: 29 68 176;
    --mspot-information-900: 29 56 134;

    --gray-50: 249 249 251;
    --gray-100: 243 244 246;
    --gray-200: 229 230 235;
    --gray-300: 209 211 219;
    --gray-400: 156 160 175;
    --gray-500: 107 111 128;
    --gray-600: 75 80 99;
    --gray-700: 55 60 81;
    --gray-800: 31 36 55;
    --gray-900: 17 21 39;
    --gray-950: 3 6 18;

    --gray-300f: 209 211 219;
    --gray-400f: 156 160 175;

    --mspot-white: 255 255 255;
    --mspot-black: 0 0 0;

    --mspot-success-50: 245 255 252;
    --mspot-success-100: 237 253 248;
    --mspot-success-300: 97 229 185;
    --mspot-success-600: 10 153 106;
    --mspot-success-800: 5 77 53;
    --mspot-success-900: 2 38 26;

    --mspot-error-700: 211 34 34;
    --mspot-error-800: 175 29 29;

    --text-primary: 0 0 0;
    --text-secondary: 38 38 38;
    --text-action-primary: 255 255 255;
    --text-action-primary-hover: 245 245 245;
    --text-action-secondary: 255 255 255;
    --text-action-tertiary: 0 0 0;
    --text-info-tertiary: 0 0 0;
    --text-warning-tertiary: 0 0 0;

    --backgrounds-bg: 250 250 250;
    --backgrounds-primary: 255 255 255;
    --backgrounds-action-primary: 26 66 229;
    --backgrounds-action-primary-hover: 27 52 178;
    --backgrounds-action-secondary: 10 10 10;
    --backgrounds-action-tertiary: 245 245 245;
    --backgrounds-info-tertiary: 219 234 254;
    --backgrounds-warning-tertiary: 254 249 195;

    --borders-primary: 245 245 245;
    --borders-secondary: 212 212 212;
    --borders-action-primary: 26 66 229;
    --borders-action-primary-hover: 27 52 178;
    --borders-action-secondary: 64 64 64;
    --borders-action-tertiary: 212 212 212;
    --borders-info-tertiary: 191 219 254;

    --form-text-label: 64 64 64;
    --form-input-text: 38 38 38;
    --form-input-placeholder: 115 115 115;
    --form-select-text: 38 38 38;
    --form-select-bg-primary: 255 255 255;
    --form-select-bg-primary-hover: 245 245 245;
    --form-select-border-primary: 229 229 229;
    --form-checkbox-bg: 255 255 255;
    --form-checkbox-bg-active: 26 66 229;
    --form-checkbox-border: 163 163 163;
    --form-checkbox-border-active: 27 52 178;

    body.dark {
      --mspot-neutral-950: 249 249 251;
      --mspot-neutral-900: 243 244 246;
      --mspot-neutral-800: 229 230 235;
      --mspot-neutral-700: 209 211 219;
      --mspot-neutral-600: 156 160 175;
      --mspot-neutral-500: 107 111 128;
      --mspot-neutral-400: 75 80 99;
      --mspot-neutral-300: 55 60 81;
      --mspot-neutral-200: 31 36 55;
      --mspot-neutral-100: 17 21 39;
      --mspot-neutral-50: 3 6 18;

      --gray-950: 249 249 251;
      --gray-900: 243 244 246;
      --gray-800: 229 230 235;
      --gray-700: 209 211 219;
      --gray-600: 156 160 175;
      --gray-500: 107 111 128;
      --gray-400: 75 80 99;
      --gray-300: 55 60 81;
      --gray-200: 31 36 55;
      --gray-100: 17 21 39;
      --gray-50: 3 6 18;

      --mspot-secondary-950: 251 238 243;
      --mspot-secondary-900: 246 202 220;
      --mspot-secondary-800: 241 167 197;
      --mspot-secondary-700: 241 126 172;
      --mspot-secondary-600: 244 82 147;
      --mspot-secondary-500: 242 44 123;
      --mspot-secondary-400: 220 24 103;
      --mspot-secondary-300: 184 20 86;
      --mspot-secondary-200: 147 16 69;
      --mspot-secondary-100: 110 12 51;
      --mspot-secondary-50: 73 8 34;

      --mspot-primary-950: 235 239 255;
      --mspot-primary-900: 194 206 255;
      --mspot-primary-800: 155 175 253;
      --mspot-primary-700: 115 143 252;
      --mspot-primary-600: 75 110 251;
      --mspot-primary-500: 44 83 242;
      --mspot-primary-400: 24 64 220;
      --mspot-primary-300: 20 53 184;
      --mspot-primary-200: 16 42 147;
      --mspot-primary-100: 12 32 110;
      --mspot-primary-50: 8 21 73;

      --mspot-information-50: 15 28 67;
      --mspot-information-100: 29 56 134;
      --mspot-information-200: 29 68 176;
      --mspot-information-600: 115 152 252;
      --mspot-information-800: 195 211 254;
      --mspot-information-900: 235 240 255;

      --mspot-black: 255 255 255;
      --mspot-white: 0 0 0;

      --mspot-success-50: 1 10 7;
      --mspot-success-100: 2 38 26;
      --mspot-success-300: 97 229 185;
      --mspot-success-600: 71 215 167;
      --mspot-success-800: 165 245 218;
      --mspot-success-900: 237 253 248;

      --mspot-error-700: 249 159 159;
      --mspot-error-800: 254 195 195;

      --text-primary: 255 255 255;
      --text-secondary: 229 229 229;
      --text-action-primary: 0 0 0;
      --text-action-primary-hover: 64 64 64;
      --text-action-secondary: 0 0 0;
      --text-action-tertiary: 255 255 255;
      --text-info-tertiary: 0 0 0;
      --text-warning-tertiary: 0 0 0;

      --backgrounds-bg: 23 23 23;
      --backgrounds-primary: 38 38 38;
      --backgrounds-action-primary: 93 155 253;
      --backgrounds-action-primary-hover: 145 190 255;
      --backgrounds-action-secondary: 245 245 245;
      --backgrounds-action-tertiary: 64 64 64;
      --backgrounds-info-tertiary: 191 219 254;
      --backgrounds-warning-tertiary: 254 249 195;

      --borders-primary: 64 64 64;
      --borders-secondary: 82 82 82;
      --borders-action-primary: 93 155 253;
      --borders-action-primary-hover: 145 190 255;
      --borders-action-secondary: 212 212 212;
      --borders-action-tertiary: 82 82 82;
      --borders-info-tertiary: 147 197 253;

      --form-text-label: 229 229 229;
      --form-input-text: 245 245 245;
      --form-input-placeholder: 212 212 212;
      --form-select-text: 245 245 245;
      --form-select-bg-primary: 64 64 64;
      --form-select-bg-primary-hover: 82 82 82;
      --form-select-border-primary: 82 82 82;
      --form-checkbox-bg: 38 38 38;
      --form-checkbox-bg-active: 93 155 253;
      --form-checkbox-border: 115 115 115;
      --form-checkbox-border-active: 145 190 255;
    }
  }

  button:focus {
    outline: none;
  }
  button:disabled:active,
  input:disabled:active,
  textarea:disabled:active {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  button:disabled,
  input:disabled,
  textarea:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

@layer utilities {
  .brightness-90 {
    filter: brightness(0.9);
  }
  .brightness-115 {
    filter: brightness(1.15);
  }
  .no-underline {
    text-decoration: none !important;
  }
}

.heading1--big {
  @apply text-4xl font-bold text-gray-900;
}

.heading1 {
  @apply text-3xl font-extrabold text-gray-900;
}

.heading2 {
  @apply text-xl font-semibold text-gray-900;
}
.heading2-new {
  @apply text-2xl font-bold text-gray-900;
}

.heading3 {
  @apply text-lg font-medium text-gray-900;
}
.heading3-new {
  @apply text-lg font-bold text-gray-900;
}
.heading-new {
  @apply text-lg font-medium leading-6 text-gray-900;
}
.heading-small {
  @apply text-sm font-medium text-gray-700;
}

.title-sm {
  @apply font-medium text-gray-900;
}

.subtitle-new {
  @apply mt-1 text-sm text-gray-900/60;
}

.subtitle-sm {
  @apply mt-1 text-xs text-gray-500;
}

.item-title,
.item-title-hover {
  @apply text-lg font-medium leading-6 text-gray-800;
}
.item-title-base {
  @apply text-base font-medium text-gray-800;
}
.item-title-hover:hover,
.item-title-base:hover {
  @apply text-gray-900 underline;
}

/*
* Buttons
*/
.btn {
  @apply flex justify-center items-center h-10 rounded-lg border px-4 text-sm font-medium;
}

.btn-xs {
  @apply flex items-center rounded-lg border py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3;
}

.btn-small {
  @apply rounded-lg border px-4 py-2 text-sm font-medium leading-4;
}

.btn--icon {
  @apply inline-flex items-center justify-center rounded-lg border px-4 py-2 text-sm font-medium;
}

.btn--icon-only {
  @apply inline-flex items-center justify-center rounded-lg border p-2 text-sm font-medium;
}

.btn--sm {
  @apply inline-flex items-center justify-center rounded-lg border px-2 py-1.5 text-sm font-medium;
}

.btn:focus-visible,
.btn--icon:focus-visible,
.btn--icon-only:focus-visible,
.btn--sm:focus-visible {
  @apply ring ring-gray-300;
}

/* primary buttons */
.btn-primary {
  @apply text-white bg-primary;
}
.btn-primary--stroked {
  @apply text-primary border-gray-300;
}
.btn-primary--basic,
.btn-primary--icon {
  @apply text-primary border-transparent;
}

.btn-primary:active:not([disabled]),
.btn-primary--icon:active:not([disabled]) {
  @apply brightness-90;
}
.btn-primary--basic:hover,
.btn-primary--basic:active {
  @apply bg-gray-200;
}

/* white buttons */
.btn-white {
  @apply bg-dwhite border-gray-300 text-gray-700;
}
.btn-white:hover {
  @apply bg-gray-200;
}

/* gray buttons */
.btn-gray {
  @apply border-gray-300 bg-gray-200 text-gray-700;
}
.btn-gray--stroked {
  @apply border-gray-900/20 text-gray-700;
}
.btn-gray--basic,
.btn-gray--icon {
  @apply border-transparent text-gray-900/80;
}

.btn-link-icon {
  @apply focus:ring-primary rounded-md py-2 pr-4 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2;
}

.btn-gray--icon-light {
  @apply border-transparent text-gray-600;
}

.btn-gray:hover,
.btn-gray:active {
  @apply bg-gray-300;
}

.btn-gray--stroked:hover,
.btn-gray--basic:hover {
  @apply bg-gray-900/10;
}

.btn-gray--stroked:active,
.btn-gray--basic:active {
  @apply bg-gray-900/10;
}

.btn-gray--icon:hover {
  @apply text-gray-800;
}
.btn-gray--icon-light:hover {
  @apply text-gray-800;
}
.btn-gray--icon:active {
  @apply text-gray-900;
}

/* red buttons */
.btn-red {
  @apply bg-red-600 text-white;
}

.btn-red--stroked {
  @apply border-gray-300 text-red-500;
}
.btn-red--basic,
.btn-red--icon {
  @apply border-transparent text-red-500;
}

.btn-red--bg {
  @apply border-transparent text-red-700;
}

.btn-red--bg\:600 {
  @apply border-transparent text-white;
}

.btn-red--bg:hover,
.btn-red--basic:hover,
.btn-red--stroked:hover {
  @apply bg-red-100;
}
.btn-red--bg:active,
.btn-red--basic:active,
.btn-red--stroked:active {
  @apply bg-red-200;
}

.btn-red--bg\:600:hover,
.btn-red--bg\:600:active {
  @apply bg-red-700;
}
.btn-red--icon:hover {
  @apply text-red-600;
}
.btn-red--icon:active {
  @apply text-red-700;
}

/* yellow buttons */
.btn-yellow--bg {
  @apply border-transparent text-yellow-700;
}
.btn-yellow--bg\:300 {
  @apply border-transparent text-gray-900;
}

.btn-yellow--bg\:300:hover,
.btn-yellow--bg\:300:active {
  @apply bg-yellow-400;
}

.btn-yellow--bg:hover {
  @apply bg-yellow-100;
}
.btn-yellow--bg:active {
  @apply bg-yellow-200;
}

/*
* etc
*/

.icon-color,
.icon-btn {
  @apply text-gray-600;
}

.icon-color-hover,
.icon-btn:hover,
.icon-btn:active {
  @apply text-gray-800;
}

.badge {
  @apply inline-flex cursor-default items-center whitespace-nowrap rounded-full px-2.5 py-0.5 text-xs font-medium;
}

.icon-with-hover {
  @apply text-gray-400 transition-colors duration-100 hover:text-gray-500;
}

.equal-number-size {
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.05rem;
}

.icon-button {
  @apply flex;
  @include actions(--mspot-primary-600);
}

.button {
  @apply flex justify-center items-center px-4 h-10 border-2 rounded-lg text-sm font-medium;
}

.button-primary {
  @apply text-mspot-white bg-mspot-primary-600  border-mspot-primary-700;
  @include actions(--mspot-primary-600);
}

.button-error {
  @apply text-mspot-white bg-mspot-error-700  border-mspot-error-800;
  @include actions(--mspot-error-700);
}

.button-error--stroked {
  @apply text-mspot-error-700  border-mspot-error-700;
  @include actions(--mspot-error-700);
}
